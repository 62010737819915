import { IosSwitch, SingleSelect } from '@LoopKitchen/loop-ui'
import { DateRange } from '@mui/icons-material'
import { Box, Button, CircularProgress, FormControlLabel, Skeleton, Typography } from '@mui/material'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import ConfirmDialog from 'src/components/ConfirmDialog'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import WarningDiamondIcon from 'src/components/Icon/WarningDiamondIcon'
import PlatformLogo from 'src/components/PlatformLogo'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { DateRangeType } from 'src/context/NewFilterContext/utils/FilterGraph'
import { useSnackData } from 'src/context/SnackContext'
import { CampaignDetailPaginatedV3, CampaignDetailPaginatedV3Paginated, DefaultService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { downloadCsv, formatCurrency, formatNumber } from 'src/utils/functions'
import { pluralize } from 'src/utils/functions/pluralize'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import LocalStorage from '../../../MarketingCampaignManagement/utils/LocalStorage'

export const CampaignFlagProperties = {
  low_performing: {
    primary: '#E52E2E',
    secondary: '#FFE5E5',
    title: 'Low Performing',
    icon: <WarningDiamondIcon sx={{ color: '#E52E2E', fontSize: '14px' }} />
  },
  recently_added: {
    primary: '#004DE5',
    secondary: '#E0EBFF',
    title: 'New',
    icon: <DateRange sx={{ color: '#004DE5', fontSize: '14px' }} />
  }
}

interface CampaignTableProps {
  period?: DateRangeType
  showRecentlyAdded?: boolean
  setShowRecentlyAdded?: (val: boolean) => void
  setShowAllCampaigns?: (val: boolean) => void
}

export default function CampaignTable({
  period = null
  // showRecentlyAdded = false,
  // setShowRecentlyAdded
}: CampaignTableProps) {
  const { headerHeightPx } = useLayoutDimension()
  const [data, setData] = React.useState<CampaignDetailPaginatedV3Paginated>(null)
  const [loading, setLoading] = React.useState(false)
  const [orderBy, setOrderBy] = React.useState<string>('cost')
  const [ascending, setAscending] = React.useState<boolean>(false)
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<CampaignDetailPaginatedV3>(null)
  const [stopCampaignLoading, setStopCampaignLoading] = React.useState<CampaignDetailPaginatedV3>(null)
  const [showLowPerformingCampaign, setShowLowPerformingCampaign] = React.useState(false)

  const [campaignTypes, setCampaignTypes] = React.useState<string[]>([])
  const [campaignTypesLoading, setCampaignTypesLoading] = React.useState<boolean>(false)
  const [selectedCampaignType, setSelectedCampaignType] = React.useState<string>()
  const { getFiltersV2, getFilters, lastDate } = useFilter()
  const { setDownloadSnack, openSuccess } = useSnackData()
  const { handleError } = useErrorData()

  const tableData = React.useMemo(() => {
    return get(data, 'data', [] as typeof data.data)
  }, [data])

  const getCSVString = async () => {
    const filterObj = {
      limit: 100,
      campaign_flag: showLowPerformingCampaign ? 'low_performing' : undefined,
      campaign_type: selectedCampaignType || undefined,
      ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
    }

    if (period) {
      filterObj['start_date_in'] = period.start.format('YYYY-MM-DD')
      filterObj['end_date_in'] = period.end.format('YYYY-MM-DD')
    }
    // if (showRecentlyAdded) {
    //   filterObj['start_date_in'] = moment(lastDate).subtract(1, 'month').format('YYYY-MM-DD')
    //   filterObj['end_date_in'] = moment(lastDate).format('YYYY-MM-DD')
    // }

    return await DefaultService.exportCampaignDetailV3ApiExportCampaignDetailV3Post(filterObj)
  }

  const exportCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const res = await getCSVString()
      downloadCsv('campaign.csv', res)
      setDownloadSnack({ status: 'complete' })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      handleError(err.message)
    }
  }

  const getData = async (
    obj: { offset: number; order_by?: string; ascending?: boolean; limit?: number },
    callback: (data: CampaignDetailPaginatedV3Paginated) => void
  ) => {
    setLoading(true)
    try {
      const filterObj = {
        limit: 100,
        campaign_flag: showLowPerformingCampaign ? 'low_performing' : undefined,
        campaign_type: selectedCampaignType || undefined,
        ...obj,
        ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      }

      if (period) {
        filterObj['start_date_in'] = period.start.format('YYYY-MM-DD')
        filterObj['end_date_in'] = period.end.format('YYYY-MM-DD')
      }
      // if (showRecentlyAdded) {
      //   filterObj['start_date_in'] = moment(lastDate).subtract(1, 'month').format('YYYY-MM-DD')
      //   filterObj['end_date_in'] = moment(lastDate).format('YYYY-MM-DD')
      // }

      const res = await DefaultService.callCampaignDetailV3ApiPaginatedCampaignDetailV3Post(filterObj, true)
      callback(res)
    } catch (err) {
      handleError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const stopCampaign = async (model: CampaignDetailPaginatedV3) => {
    setStopCampaignLoading(JSON.parse(JSON.stringify(model)))

    try {
      let res = await DefaultService.stopCampaignsStopCampaignPost([{ id: model.campaignId, slug: model.slug }])
      LocalStorage.addActiveCampaignID(model.campaignId)
      openSuccess('Campaign Stopped')
    } catch (err) {
      handleError(err.message)
    }

    setStopCampaignLoading(null)

    getData(
      {
        limit: Math.max(100, get(data, 'data.length', 0)),
        offset: 0
      },
      (data) => {
        setData(data)
      }
    )
  }

  React.useEffect(() => {
    getData({ offset: 0, order_by: orderBy, ascending }, (res) => {
      setData(res)
    })
  }, [
    orderBy,
    ascending,
    showLowPerformingCampaign,
    selectedCampaignType,
    // showRecentlyAdded,
    period,
    ...getFilters(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])
  ])

  React.useEffect(() => {
    const getCampaignTypes = async () => {
      setCampaignTypesLoading(true)

      try {
        const res = await DefaultService.getCampaignTypesGetCampaignTypesGet()
        setCampaignTypes(res)
      } catch (err) {
        handleError(err.message)
      }

      setCampaignTypesLoading(false)
    }

    getCampaignTypes()
  }, [])

  return (
    <>
      <ConfirmDialog
        open={!!openConfirmDialog}
        title="Do you want to stop the campaign?"
        description=""
        onConfirm={() => {
          stopCampaign(openConfirmDialog)
          setOpenConfirmDialog(null)
        }}
        handleClose={() => {
          setOpenConfirmDialog(null)
        }}
      />
      <Box>
        <Box
          display="flex"
          alignItems="center"
          gap={3}>
          {campaignTypesLoading ? (
            <Skeleton
              variant="rounded"
              width={140}
              height={40}
            />
          ) : (
            campaignTypes.length > 0 && (
              <SingleSelect
                value={selectedCampaignType}
                onChange={(val) => {
                  setSelectedCampaignType(val)
                }}
                options={[
                  {
                    label: 'All',
                    value: ''
                  },
                  ...campaignTypes.map((e) => {
                    return {
                      label: e.replaceAll('_', ' '),
                      value: e
                    }
                  })
                ]}
                selectButtonSx={{
                  height: '40px',
                  width: '140px',
                  bgcolor: '#F6f6f6',
                  color: '#000',
                  '&:hover': {
                    bgcolor: '#EEEEEE'
                  },
                  '& p': {
                    color: '#000'
                  },
                  borderRadius: '4px',
                  px: '16px',
                  py: '5px'
                }}
                renderValue={(value) => {
                  const val = value || 'Campaign Type'
                  return (
                    <Typography
                      fontSize="12px"
                      fontWeight={600}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      {val}
                    </Typography>
                  )
                }}
              />
            )
          )}

          <FormControlLabel
            value="low_performing"
            control={
              <IosSwitch
                icon={
                  <Box
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: '50%',
                      backgroundColor: '#fff'
                    }}
                  />
                }
                checkedIcon={
                  <Box
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: '50%',
                      backgroundColor: '#fff'
                    }}
                  />
                }
                sx={{ height: '18px', width: '34px' }}
              />
            }
            label={
              <Typography
                fontSize="12px"
                fontWeight={600}
                color="#696C80"
                sx={{ ml: 1 }}>
                Low Performing Campaigns
              </Typography>
            }
            checked={showLowPerformingCampaign}
            onChange={(e, checked) => {
              setShowLowPerformingCampaign(checked)
            }}
            sx={{ m: 0 }}
          />

          {/* {!period && (
              <FormControlLabel
                value="recently_added"
                control={
                  <IosSwitch
                    icon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    checkedIcon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    sx={{ height: '18px', width: '34px' }}
                  />
                }
                label={
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="#696C80"
                    sx={{ ml: 1 }}>
                    Recently Added (Last 30 days)
                  </Typography>
                }
                checked={showRecentlyAdded}
                onChange={(e, checked) => {
                  setShowRecentlyAdded(checked)
                }}
                sx={{ m: 0 }}
              />
          )} */}
        </Box>

        <CustomMaterialTableWrapper
          data={tableData}
          isLoading={loading}
          columns={[
            {
              title: 'Campaign',
              field: 'campaignName',
              cellStyle: { minWidth: '250px' },
              render: (data) => {
                return (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={2}
                      p={1}
                      bgcolor="#F4F4F4"
                      borderRadius="4px"
                      minWidth={270}
                      // sx={{
                      //   cursor: 'pointer',
                      //   transition: '0.3s ease',
                      //   '&:hover': {
                      //     bgcolor: '#E6E6E6'
                      //   }
                      // }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={1}>
                        <Box
                          p={'2px'}
                          bgcolor="#fff">
                          <PlatformLogo
                            shortImg
                            platformName={get(data, 'vb_platform', '')}
                            width="24px"
                            height="26px"
                            boxSx={{
                              display: 'flex',
                              alignItems: 'center',
                              flex: 'none'
                            }}
                          />
                        </Box>

                        <Typography
                          fontSize="13px"
                          fontWeight={600}
                          color="#000"
                          lineHeight="initial">
                          {get(data, 'campaignName', '')}
                        </Typography>
                      </Box>

                      {/* <ChevronRight sx={{ fontSize: '20px', color: '#000' }} /> */}
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      gap={1}
                      mt={1}>
                      {get(data, 'campaign_flag', '') === 'low_performing' && (
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="4px 10px"
                          borderRadius="12px"
                          width="fit-content"
                          bgcolor={CampaignFlagProperties['low_performing'].secondary}
                          gap={0.5}>
                          {CampaignFlagProperties['low_performing'].icon}
                          <Typography
                            color={CampaignFlagProperties['low_performing'].primary}
                            fontWeight={500}
                            fontSize="11px"
                            lineHeight="initial"
                            whiteSpace="nowrap">
                            {CampaignFlagProperties['low_performing'].title}
                          </Typography>
                        </Box>
                      )}

                      {moment(get(data, 'startTime', '')).isSameOrAfter(moment(lastDate).subtract(1, 'month')) && (
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="4px 10px"
                          borderRadius="12px"
                          width="fit-content"
                          bgcolor={CampaignFlagProperties['recently_added'].secondary}
                          gap={0.5}>
                          {CampaignFlagProperties['recently_added'].icon}
                          <Typography
                            color={CampaignFlagProperties['recently_added'].primary}
                            fontWeight={500}
                            fontSize="11px"
                            lineHeight="initial"
                            whiteSpace="nowrap">
                            {CampaignFlagProperties['recently_added'].title}
                          </Typography>
                        </Box>
                      )}

                      {get(data, 'startTime', null) && (
                        <Typography
                          fontSize="12px"
                          color="#000">
                          From {moment(get(data, 'startTime', '')).format('DD MMM YYYY')}
                          {` till ${!data.endTime || data.endTime === null || data.endTime === '' || Math.abs(moment().diff(moment(get(data, 'endTime', '')), 'years')) >= 90 ? 'Indefinite' : moment(get(data, 'endTime', '')).format('DD MMM YYYY')}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )
              }
            },
            {
              title: filterNames.vb_name,
              field: 'vb_name',
              cellStyle: { minWidth: '200px' },
              render: (data) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={0.5}>
                    <Typography
                      fontSize="12px"
                      fontWeight={600}
                      color="#000">
                      {get(data, 'vb_name', '')}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#000">
                      {get(data, 'b_name', '')}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#0008">
                      {get(data, 'vb_address', '')}
                    </Typography>
                  </Box>
                )
              }
            },
            {
              title: 'Spend',
              field: 'cost',
              alignType: 'currency',
              render: (data) => {
                return (
                  <Typography
                    fontSize="14px"
                    color="#000">
                    {formatCurrency(get(data, 'cost', 0), { maxFractionDigits: 2 })}
                  </Typography>
                )
              }
            },
            {
              title: 'Sales',
              field: 'sales',
              alignType: 'currency',
              render: (data) => {
                return (
                  <Box>
                    <Typography
                      fontSize="14px"
                      color="#0E8C43">
                      {formatCurrency(get(data, 'sales', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      color="#A0A0A0">
                      {get(data, 'orders', 0)} {pluralize('order', get(data, 'orders', 0))}
                    </Typography>
                  </Box>
                )
              }
            },
            {
              title: 'ROI',
              field: 'roi',
              sortable: true,
              alignType: 'currency',
              render: (data) => {
                const roi = get(data, 'roi', 0)
                return (
                  <Typography
                    fontSize="14px"
                    color="#000">
                    {formatNumber(get(data, 'roi', 0), { maxFractionDigits: 2 })}
                  </Typography>
                )
              }
            },
            {
              title: 'Action',
              field: 'action',
              sortable: false,
              hideInExport: true,
              render: (data) => {
                if (!data?.stoppable) {
                  return (
                    <Typography
                      fontSize="12px"
                      color="#0008">
                      N/A
                    </Typography>
                  )
                }

                const isLoading = get(data, 'campaignId', undefined) === get(stopCampaignLoading, 'campaignId', undefined)
                return (
                  <Button
                    size="small"
                    disabled={isLoading}
                    onClick={() => {
                      setOpenConfirmDialog(data)
                    }}
                    sx={{
                      bgcolor: '#E52E2E1A',
                      color: '#E52E2E',
                      py: 1,
                      px: 2,
                      whiteSpace: 'nowrap',
                      '&:hover': { bgcolor: '#E52E2E33' }
                    }}
                    startIcon={
                      isLoading && (
                        <CircularProgress
                          size="14px"
                          color="secondary"
                        />
                      )
                    }>
                    STOP
                  </Button>
                )
              }
            }
          ]}
          options={{
            stickyHeader: period ? '-17px' : headerHeightPx,
            export: true,
            extraExportColumns: [
              {
                title: filterNames.b_name,
                field: 'b_name'
              },
              {
                title: filterNames.vb_address,
                field: 'vb_address'
              },
              {
                title: filterNames.vb_platform,
                field: 'vb_platform'
              },
              {
                title: 'Campaign flag',
                field: 'campaign_flag'
              },
              {
                title: 'Start Time',
                field: 'startTime'
              },
              {
                title: 'End Time',
                field: 'endTime'
              },
              {
                title: 'Orders',
                field: 'orders'
              },
              {
                title: 'Store ID',
                field: 'store_id'
              }
            ],
            search: true,
            sortable: true,
            showOrderBy: true,
            initialOrderBy: ascending ? 'ascending' : 'descending',
            pagination: true,
            totalPaginatedDataLength: get(data, 'max_rows', undefined)
          }}
          onExportModifyCSV={getCSVString}
          onExportCSV={() => {
            exportCSV()
          }}
          onSearchChange={(searchText) => {
            // Call API with search text
          }}
          onOrderByChange={(type) => {
            setAscending(type === 'ascending')
          }}
          onSortByChange={(option) => {
            setOrderBy(option.value)
          }}
          onLastPage={() => {
            const nextOffset = get(data, 'next_offset', undefined)
            if (nextOffset) {
              getData({ offset: nextOffset, order_by: orderBy, ascending }, (res) => {
                setData((prev) => ({
                  ...res,
                  data: [...get(prev, 'data', []), ...get(res, 'data', [])]
                }))
              })
            }
          }}
        />
      </Box>
    </>
  )
}
