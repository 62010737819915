import { captureEvent } from '@sentry/react'
import { Slack } from 'src/services'
import { DefaultService, EmailDigestRequest } from 'src/services/openApi'
import { User } from 'src/types/user'
import { LOOP_SUPPORT_EMAIL } from './config/config'

const slackService = new Slack()

interface slackMessage {
  title: string
  message: string
  channel?: string
  test?: boolean
}

export default function getData(procedureName, data) {
  return fetch(`https://api.loopapplication.xyz/run/query?procedure_name=data_visualization_metrics.${procedureName}`, {
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(data),
    method: 'POST'
  })
}

const sendSlackNotification = async (
  slackMessageObject: slackMessage = {
    title: '',
    message: '',
    channel: 'fe-logs',
    test: false
  }
) => {
  try {
    if (process.env.NODE_ENV === 'production' || slackMessageObject.test) {
      return await slackService.postSlack({
        channel: 'frontend-alerts',
        ...slackMessageObject
      })
    }
  } catch (error) {
    console.error('Failed to send Slack notification:', error)
    // return await slackService.postSlack({
    //   ...slackMessageObject
    // })
  }
}

interface SendSupportEmailParams {
  currentUser: User
  templateName: string
  emailBody: Object
  fromEmail?: string
  toEmails?: string[]
  ccEmails?: string[]
  replyToEmail?: string
  replyToName?: string
}

const sendSupportEmail = async ({
  currentUser,
  templateName,
  emailBody,
  fromEmail = LOOP_SUPPORT_EMAIL,
  toEmails = [LOOP_SUPPORT_EMAIL],
  ccEmails = [],
  replyToEmail,
  replyToName
}: SendSupportEmailParams) => {
  const requestBody: EmailDigestRequest = {
    template_name: templateName,
    from_email: fromEmail,
    to_emails: toEmails,
    cc_emails: ccEmails,
    reply_to_email: replyToEmail ?? currentUser?.email,
    reply_to_name: replyToName ?? currentUser?.name,
    params: {
      b_name_in: ''
    },
    extra_data: emailBody
  }

  try {
    await DefaultService.triggerEmailDigestNotificationEmailDigestPost(requestBody)
  } catch (error) {
    console.error('Failed to send support email:', error)

    captureEvent({
      message: 'Failed to send support email',
      level: 'error',
      tags: {
        type: 'bug-report',
        org: currentUser?.org,
        email: currentUser?.email,
        name: currentUser?.name
      },
      extra: requestBody
    })
  }
}

export { sendSlackNotification, sendSupportEmail }
