import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import get from 'lodash/get'
import React, { useState } from 'react'
// import Button from '@mui/material/Button'
import moment from 'moment'
import PlatformLogo from 'src/components/PlatformLogo'
import CustomTypo from 'src/components/StyledTypo'
import { useFilter } from 'src/context/FilterContext'
import { formatCurrency, getShortOrderId } from 'src/utils/functions'
// import DeltaCard from 'src/components/DeltaCard';
import { alpha } from '@mui/material/styles'
// import usePrevDateRange from 'src/utils/hooks/usePrevDateRange';
import Stack from '@mui/material/Stack'
import { useAuth } from 'src/context/AuthContext'
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// import { styled } from '@mui/styles';
// import { Link } from 'react-router-dom';
import { MultiSelect } from '@LoopKitchen/loop-ui'
import { Button, CircularProgress, Dialog, DialogContent } from '@mui/material'
import { debounce } from 'lodash'
import Logo1 from 'src/assets/logos/logo1.png'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import OrderDrawer from 'src/components/OrderDrawer/OrderDrawer'
import { useTabPanelContext } from 'src/components/TabPanel'
import { useErrorData } from 'src/context/ErrorContext'
import { useLoadingData } from 'src/context/LoadingContext'
import { useSnackData } from 'src/context/SnackContext'
import {
  DefaultService as ActionsService,
  ChargebackOrderRow,
  ChargebackOrdersV2,
  DefaultService,
  DisputeData,
  DisputeStatus,
  ExternalStatus,
  LoopDisputeState,
  OrderContextModel
} from 'src/services/openApi'
import { Modules, filterNames } from 'src/utils/config/config'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import usePostHogHook from 'src/utils/hooks/usePostHogHook'
import EmptyTemplate from '../components/EmptyTemplate'
import ItemLevelRaiseDisputeDialog from '../components/ItemLevelRaiseDisputeDialog'
import OrderLevelRaiseDisputeDialog from '../components/OrderLevelRaiseDisputeDialog'
import RaiseDisputeDialog, { RaiseDialogDataType } from '../components/RaiseDisputeDialog'
import ReasonContextProvider from '../components/ReasonContext'
import StatusLogo from '../components/StatusLogo'
import getChargebacksOrders from '../utils/api/getChargebacksOrders'

export type ItemLevelType = {
  id: string | number
  name: string
  quantity: number
  display_price: string
  error: string
  reason: string
  description?: string
}
export type OrderLevelType = {
  id: string | number
  name: string
  quantity: number
  display_price: string
  error: string
}

type ChangeDispute =
  | {
      type: 'raise'
      reason: string
      description?: string
      items?: OrderLevelType[]
    }
  | { type: 'raise'; items: ItemLevelType[] }
  | { type: 'raise'; cancelledOrder: true }
  | { type: 'mark' }

export type ItemType = {
  amount: number
  currency: string
  display_price: string
  error: string
  id: number | string
  name: string
  quantity: number
}

export interface ErrorMetricsTypes {
  total_error_charges: number
  num_disputes: number
  num_eligible_disputes: number
}
interface TableChipProps {
  label: string
  variant?: string
}

// const LightTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: "white",
//         fontSize: 11,
//     },
// }));

const TableText = (props: { children: React.ReactNode }) => (
  <CustomTypo
    fs={16}
    fw={400}>
    {props.children}
  </CustomTypo>
)
const TableChip = (props: TableChipProps) => {
  const bgcolor = props.variant === 'primary' ? '#E0EBFF' : props.variant === 'error' ? '#FFE6E6' : props.variant === 'warning' ? '#FDE9DB' : '#EBECF2'
  const color = props.variant === 'primary' ? '#004DE6' : props.variant === 'error' ? '#E62E2E' : props.variant === 'warning' ? '#A1673F' : '#12131A'
  return (
    <Chip
      label={
        <CustomTypo
          fw={400}
          color={color}
          sx={{ whiteSpace: 'normal', p: 1 }}>
          {props.label}
        </CustomTypo>
      }
      sx={{
        bgcolor,
        height: 'auto'
      }}
    />
  )
}

interface ErrorsRevenueRecaptureProps {
  // disputeData?: DisputeResponse
}
export interface OrderContextWithLoading extends OrderContextModel {
  loading: boolean
}
function ErrorsRevenueRecapture(props: ErrorsRevenueRecaptureProps) {
  const { tabFocused } = useTabPanelContext()
  const { handleError } = useErrorData()
  const { headerHeightPx } = useLayoutDimension()
  const [disputeData, setDisputeData] = useState<ChargebackOrdersV2>(null)
  // const dateRange = usePrevDateRange()
  const { trackPostHogDownload } = usePostHogHook()
  const { setBackdropLoading } = useLoadingData()
  const { getFilters, getFiltersV2 } = useFilter()
  const { orgConfig, currentUser } = useAuth()
  const { openError, setSnack, setDownloadSnack } = useSnackData()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [orderId, setOrderId] = useState<string | null>(null)
  const [raiseDisputeData, setRaiseDisputeData] = React.useState<RaiseDialogDataType | null>(null)
  const [markDisputeData, setMarkDisputeData] = React.useState<RaiseDialogDataType | null>(null)
  const [itemLevelDisputeData, setItemLevelDisputeData] = React.useState<ChargebackOrderRow | null>(null)
  const [orderLevelDisputeData, setOrderLevelDisputeData] = React.useState<ChargebackOrderRow | null>(null)
  const [disputeConfirmData, setDisputeConfirmData] = React.useState<ChargebackOrderRow | null>(null)
  const [extraOrderDrawerData, setExtraOrderDrawerData] = React.useState<{
    dispatch_timestamp: string
  }>(null)
  const [extraDisputeDataLoading, setExtraDisputeDataLoading] = React.useState<string>(null)
  // const [errorMetrics, setErrorMetrics] = useState<ErrorMetricsTypes>({
  //     total_error_charges: 0,
  //     num_disputes: 0,
  //     num_eligible_disputes: 0
  // })
  const [data, setData] = React.useState<ChargebackOrderRow[]>([])
  // const [data, setData] = React.useState<DisputeResponse>(DisputeResponse())
  const [tableLoading, setTableLoading] = React.useState(false)
  const [pageNum, setPageNum] = React.useState(0)
  const [searchTerm, setSearchTerm] = React.useState('')

  const externalStatus = React.useMemo(() => {
    const arr = [
      ExternalStatus.ACCEPTED,
      ExternalStatus.IN_PROGRESS,
      ExternalStatus.DENIED,
      ExternalStatus.TO_BE_RAISED,
      ExternalStatus.EXPIRED,
      ExternalStatus.NOT_ELIGIBLE
    ]
    return arr
  }, [])

  const [externalStatusSelected, setExternalStatusSelected] = React.useState<ExternalStatus[]>([...externalStatus])

  const requestBody = React.useMemo(() => {
    return {
      ...getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'], true),
      search_term: searchTerm,
      external_status_in: externalStatusSelected.length > 0 ? externalStatusSelected.join('|') : 'ACCEPTED|IN_PROGRESS|DENIED',
      order_state_in: 'CHARGEBACK|LOST_SALE|REVERSAL'
    }
  }, [externalStatusSelected, searchTerm, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'])])

  const filteredData = React.useMemo(() => {
    if (!Array.isArray(data)) return []
    return data.filter((e) => externalStatusSelected.includes(e.external_status))
  }, [data, externalStatusSelected])

  const raiseDispute = async (data: ChargebackOrderRow) => {
    const raisable_type = get(data, 'raisable_type', '').toLowerCase()
    let newData = {
      ...data
    }

    // Get extra data only when raisable_type is API
    async function getExtraData() {
      if (raisable_type.toLowerCase() !== 'api') {
        return
      }
      setExtraDisputeDataLoading(get(data, 'order_id', null))
      const extraData = await DefaultService.getChargebackOrderJsonChargebacksOrdersOrderIdPost(get(data, 'order_id', ''), get(data, 'order_timestamp', ''))
      console.log('extra data: ', { extraData, data })
      setExtraDisputeDataLoading(null)
      newData = {
        ...newData,
        item_level_error: get(extraData, 'item_level_error', false),
        items: get(extraData, 'items', [])
      }
    }
    await getExtraData()
    if (raisable_type.toLowerCase() === 'api' && newData.vb_platform.toLowerCase() === 'doordash' && newData.item_level_error) {
      setItemLevelDisputeData(newData)
    } else if (raisable_type.toLowerCase() === 'api' && newData.vb_platform.toLowerCase() === 'ubereats' && newData.item_level_error) {
      setOrderLevelDisputeData(newData)
    } else if (raisable_type.toLowerCase() === 'api' && !newData.item_level_error) {
      setRaiseDisputeData(newData)
    } else if (raisable_type.toLowerCase() === 'manual') {
      setDisputeConfirmData(newData)
    }
  }

  const getDataByOrderID = async (order_date: string, order_id: string, slug: string) => {
    setOrderId(order_id)
  }

  async function getOrderDetails(data: ChargebackOrderRow) {
    try {
      const emptyOrder = 'Order not found'
      setBackdropLoading(true)
      const response = await DefaultService.getChargebackOrderJsonChargebacksOrdersOrderIdPost(data.order_id, data.order_timestamp)
      if (response === emptyOrder) throw new Error(emptyOrder)
      setBackdropLoading(false)
      return response
    } catch (error) {
      setSnack({ open: true, type: 'error', text: error.message })
      setBackdropLoading(false)
    }
  }

  const changeDispute = async (data: RaiseDialogDataType, d: ChangeDispute) => {
    setBackdropLoading(true)
    try {
      const uid = get(currentUser, 'uid', '')
      const obj: DisputeData = {
        user_id: uid,
        slug: data.slug,
        state: d.type === 'mark' ? LoopDisputeState.UNAUTHORIZED : LoopDisputeState.AUTHORIZED
      }
      if (d.type === 'raise' && !('cancelledOrder' in d)) {
        if ('reason' in d) {
          obj['reason'] = d.reason as any
          if ('items' in d) {
            obj['items'] = d.items
          }
        }
        if ('description' in d) {
          obj['description'] = d.description
        }
        if (!('reason' in d)) {
          obj['items'] = d.items
        }
      }
      // return;

      const result: any = await ActionsService.disputeOrderDisputeOrderPut(data.order_id, obj)

      if (typeof result === 'string') {
        setSnack({ open: true, type: 'info', text: result })
      }
      if (d.type === 'mark') {
        setData((prevData) => {
          const arr: typeof prevData = JSON.parse(JSON.stringify(prevData))
          const index = arr.findIndex((e) => e.order_id === data.order_id)
          if (index >= 0) {
            arr[index] = {
              ...arr[index],
              dispute_status: DisputeStatus.UNAUTHORIZED
            }
          }
          return arr
        })
      } else if (d.type === 'raise') {
        setData((prevData) => {
          const arr: typeof prevData = JSON.parse(JSON.stringify(prevData))
          const index = arr.findIndex((e) => e.order_id === data.order_id)
          if (index >= 0) {
            arr[index] = {
              ...arr[index],
              dispute_status: DisputeStatus.PENDING
            }
          }
          return arr
        })
      }
    } catch (err) {
      openError(err.message)
    }
    setBackdropLoading(false)

    // const result = "Success";
    // setTimeout(() => {
    //     setBackdropLoading(false);
    //     setSnack({ open: true, type: "info", text: result });
    //     if (d.type === "mark") {
    //         setData((prevData) => {
    //             let arr: typeof prevData = JSON.parse(JSON.stringify(prevData));
    //             let index = arr.findIndex(e => e.order_id === data.orderID);
    //             if (index >= 0) {
    //                 arr[index] = {
    //                     ...arr[index],
    //                     dispute_status: "NOT_ELIGIBLE"
    //                 }
    //             }
    //             return arr;
    //         })
    //     }
    // }, 2000);
  }

  const downloadCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      let res = await DefaultService.generateCsvFromProcedureGenerateCsvPost('chargebacks/api/chargeback_orders', requestBody)
      // setCsvObj({
      //   csvData: res,
      //   fileName: 'Errors Revenue Recapture.csv'
      //   // predefinedKeys: {
      //   //   error_charges: { type: 'number', subType: 'currency' }
      //   // }
      // })
      let blob = new Blob([res], { type: 'text/csv' })
      let url = URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.download = 'Errors Revenue Recapture.csv'
      a.href = url
      a.click()
      setDownloadSnack({ status: 'complete' })
      trackPostHogDownload({
        fileName: a.download,
        type: 'CSV'
      })
      downloadSuccessSlackAlert({ apiUrl: '/generate/csv', procedureName: 'chargebacks/api/chargeback_orders' })
    } catch (err) {
      handleError(err.message)
      setDownloadSnack({ status: 'close' })
      downloadFailedSlackAlert({ err, apiUrl: '/generate/csv', procedureName: 'chargebacks/api/chargeback_orders' })
    }
  }

  React.useEffect(() => {
    const getDisputes = () => {
      const result = disputeData
      // const externalList = [
      //   ...new Set(result.disputes.map((e) => e.external_status))
      // ]
      // setExternalStatusSelected((p) => {
      //   if (p.length === 0) {
      //     return externalList
      //   }
      //   return p.filter((e) => externalList.includes(e))
      // })
      // setExternalStatus(externalList)
      let arr = result.disputes.sort((a, b) => get(b, 'num_days_to_dispute', 0) - get(a, 'num_days_to_dispute', 0))
      setData(arr)
      // setErrorMetrics({
      //     total_error_charges: result.total_error_charges,
      //     num_disputes: result.num_disputes,
      //     num_eligible_disputes: result.num_eligible_disputes
      // });
    }
    if (disputeData) {
      getDisputes()
    }
  }, [disputeData])

  React.useEffect(() => {
    if (tabFocused) {
      // getChargebacksOrders({
      //   requestBody,
      //   limit: Math.max(100, get(disputeData, 'disputes.length', 0)),
      //   offset: 0,
      //   onSuccess: (data) => {
      //     // console.log('dispute data: ', data)
      //     setDisputeData(data)
      //     setPageNum(0)
      //   },
      //   onError: (err) => {
      //     handleError(err.message)
      //   },
      //   onStart: () => {
      //     setTableLoading(true)
      //   },
      //   onEnd: () => {
      //     setTableLoading(false)
      //   }
      // })

      const debouncedGetDisputes = debounce(
        () =>
          getChargebacksOrders({
            requestBody,
            limit: Math.max(100, get(disputeData, 'disputes.length', 0)),
            offset: 0,
            onSuccess: (data) => {
              // console.log('dispute data: ', data)
              setDisputeData(data)
              setPageNum(0)
            },
            onError: (err) => {
              handleError(err.message)
            },
            onStart: () => {
              setTableLoading(true)
            },
            onEnd: () => {
              setTableLoading(false)
            }
          }),
        2000
      )

      debouncedGetDisputes()

      return () => {
        debouncedGetDisputes.cancel()
      }
    }
  }, [tabFocused, ...Object.values(requestBody)])

  return (
    <ReasonContextProvider>
      <ItemLevelRaiseDisputeDialog
        data={itemLevelDisputeData}
        onClose={() => {
          setItemLevelDisputeData(null)
        }}
        onRaise={async (itemlist) => {
          await changeDispute(itemLevelDisputeData, {
            type: 'raise',
            items: itemlist
          })
          setItemLevelDisputeData(null)
        }}
      />
      <OrderLevelRaiseDisputeDialog
        data={orderLevelDisputeData}
        onClose={() => {
          setOrderLevelDisputeData(null)
        }}
        onRaise={async (itemlist, reason, description) => {
          await changeDispute(orderLevelDisputeData, {
            type: 'raise',
            reason,
            items: itemlist,
            ...(description && description.length ? { description } : {})
          })
          setOrderLevelDisputeData(null)
        }}
      />
      <Dialog
        open={markDisputeData !== null}
        onClose={() => {
          setMarkDisputeData(null)
        }}>
        <DialogContent>
          <Typography variant="h3">Are you sure you want to make this order not eligible?</Typography>
          <Typography variant="subtitle2">
            Order ID: <b>{getShortOrderId(markDisputeData?.vb_platform, markDisputeData?.order_id)}</b>
          </Typography>
          <Stack
            direction={'row'}
            justifyContent="flex-end"
            spacing={2}
            mt={2}>
            <Button
              variant="text"
              onClick={() => {
                setMarkDisputeData(null)
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                changeDispute(JSON.parse(JSON.stringify(markDisputeData)), {
                  type: 'mark'
                })
                setMarkDisputeData(null)
              }}>
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* Confirmation dialog for CANCELLED_NOT_PAID dispute_status or vb_platform is Grubhub */}
      <Dialog
        open={disputeConfirmData !== null}
        onClose={() => {
          setDisputeConfirmData(null)
        }}>
        <DialogContent>
          <Typography variant="h3">Are you sure you want to raise a Dispute for this order?</Typography>
          <Typography variant="subtitle2">
            Order ID: <b>{getShortOrderId(disputeConfirmData?.vb_platform, disputeConfirmData?.order_id)}</b>
          </Typography>
          <Stack
            direction={'row'}
            justifyContent="flex-end"
            spacing={2}
            mt={2}>
            <Button
              variant="text"
              onClick={() => {
                setDisputeConfirmData(null)
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                changeDispute(JSON.parse(JSON.stringify(disputeConfirmData)), {
                  type: 'raise',
                  cancelledOrder: true
                })
                setDisputeConfirmData(null)
              }}>
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <RaiseDisputeDialog
        data={raiseDisputeData}
        onClose={() => {
          setRaiseDisputeData(null)
        }}
        onRaise={(reason, item, desc) => {
          setRaiseDisputeData(null)
          changeDispute(item, { type: 'raise', reason, description: desc })
        }}
      />
      <OrderDrawer
        open={!!orderId}
        orderId={orderId}
        onClose={() => {
          setOrderId(null)
        }}
      />
      {/* <OrderDrawer
        subCategory={get(drawerData, 'error_subcategory', '')}
        open={isDrawerOpen}
        dispatch_timestamp={extraOrderDrawerData?.dispatch_timestamp}
        onClose={() => {
          setIsDrawerOpen(false)
          setExtraOrderDrawerData(null)
        }}
        drawerData={drawerData}
      /> */}

      {/* <Stack direction="row" alignItems="center" justifyContent={"flex-end"} py={2} px={4} >
                <Typography variant="h3" flex={1} >Dispute Details</Typography>
                <Box sx={{ minWidth: "300px", maxWidth: "300px" }} >
                    <FormControl fullWidth >
                        <InputLabel id="select-status-label" >Select Status</InputLabel>
                        <Select
                            labelId="select-status-label"
                            label="Select Status"
                            multiple
                            value={disputeStatus}
                            onChange={(e) => {
                                if (Array.isArray(e.target.value)) {
                                    setDisputeStatus(e.target.value);
                                }
                            }}
                            renderValue={(e) => e.map((str, i) => <Typography component="span" >{capitalize(str.replaceAll("_", " ").toLowerCase())}{i < e.length - 1 ? ", " : ""}</Typography>)}
                        >
                            {disputeStatusList !== null && disputeStatusList.map((e) => {
                                return (
                                    <MenuItem key={e} value={e} >
                                        <Checkbox checked={disputeStatus.includes(e)} />
                                        {capitalize(e.split("_").join(" ").toLowerCase())}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </Stack> */}
      {/* <Paper sx={{ px: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} >
                        <Card variant="outlined" >
                            <CardContent>
                                <Typography sx={{ fontSize: '0.9rem', flex: 1, fontWeight: 700, lineHeight: '1.2em', display: '-webkit-box', WebkitBoxOrient: "vertical", boxOrient: "vertical", WebkitLineClamp: 2, lineClamp: 2, overflow: "hidden", textOverflow: "ellipsis" }}># of Orders with Error Charges</Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: '1.6rem' }} title="num_disputes">{get(errorMetrics, 'num_disputes', 0).toLocaleString()}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} >
                        <Card variant="outlined" >
                            <CardContent>
                                <Typography sx={{ fontSize: '0.9rem', flex: 1, fontWeight: 700, lineHeight: '1.2em', display: '-webkit-box', WebkitBoxOrient: "vertical", boxOrient: "vertical", WebkitLineClamp: 2, lineClamp: 2, overflow: "hidden", textOverflow: "ellipsis" }}>Error Charges</Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: '1.6rem' }} title="total_error_charges">{formatCurrency(get(errorMetrics, 'total_error_charges', 0))}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} >
                        <Card variant="outlined" >
                            <CardContent>
                                <Typography sx={{ fontSize: '0.9rem', flex: 1, fontWeight: 700, lineHeight: '1.2em', display: '-webkit-box', WebkitBoxOrient: "vertical", boxOrient: "vertical", WebkitLineClamp: 2, lineClamp: 2, overflow: "hidden", textOverflow: "ellipsis" }}># of Orders Eligible for Dispute</Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: '1.6rem' }} title="num_eligible_disputes">{get(errorMetrics, 'num_eligible_disputes', 0).toLocaleString()}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper> */}

      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        sx={{
          '& tbody': {
            '& th, td': {
              border: `1px solid ${alpha('#000000', 0.1)}`
            }
          },
          '& thead': {
            border: `1px solid ${alpha('#000000', 0.1)}`
          }
        }}>
        <Typography variant="h3">Orders with disputes</Typography>
        <CustomMaterialTableWrapper
          isLoading={tableLoading}
          data={filteredData}
          options={{
            pageNumber: pageNum,
            stickyHeader: headerHeightPx,
            search: true,
            export: true,
            csvFileName: 'Errors Revenue Recapture.csv',
            pagination: true,
            totalPaginatedDataLength: get(disputeData, 'max_rows', undefined),
            extraSearchFields: ['external_status', 'dispute_status'],
            extraExportColumns: [
              { title: filterNames.vb_platform, field: 'vb_platform' },
              { title: 'Store ID', field: 'store_id' }
            ]
          }}
          columns={[
            {
              title: 'Order Id',
              field: 'order_id',
              headerStyle: { maxWidth: '100px' }, // TEST TODO
              render: (data) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}>
                  {get(orgConfig, 'filterConfig.vb_platform', false) && (
                    <Box>
                      <PlatformLogo
                        shortImg
                        platformName={data.vb_platform}
                        height="23px"
                        width="25px"
                      />
                    </Box>
                  )}
                  <Button
                    variant="text"
                    onClick={() => {
                      getDataByOrderID(data.order_timestamp, data.order_id, data.slug)
                      // To be added future
                      // setExtraOrderDrawerData({
                      //   dispatch_timestamp: data.dispatch_timestamp
                      // })
                    }}>
                    {getShortOrderId(data.vb_platform, data.order_id)}
                  </Button>
                </Stack>
              )
            },
            {
              title: filterNames.b_name,
              field: 'b_name',
              hidden: get(orgConfig, 'filterConfig.b_name', false) === false
            },
            {
              title: 'Brand',
              field: 'vb_name',
              hidden: get(orgConfig, 'filterConfig.vb_name', false) === false
            },
            // {
            //   title: 'filterNames.vb_platform',
            //   field: 'vb_platform',
            //   render: (data: Dispute) => (
            //     <PlatformLogo
            //       platformName={data.vb_platform}
            //       height={
            //         ['grubhub'].includes(data.vb_platform.toLocaleLowerCase())
            //           ? '15px'
            //           : '40px'
            //       }
            //     />
            //   ),
            //   hidden:
            //     get(orgConfig, 'filterConfig.vb_platform', false) ===
            //     false
            // },
            {
              title: Modules.Chargebacks,
              field: 'error_charges',
              alignType: 'currency',
              cellStyle: { minWidth: '200px' },
              render: (data) => <Typography variant="subtitle2">{formatCurrency(parseFloat(get(data, 'error_charges', '').toString()))}</Typography>
            },
            {
              title: 'Adjustment',
              field: 'adjustment',
              alignType: 'currency',
              cellStyle: { minWidth: '200px' },
              render: (data) => <Typography variant="subtitle2">{formatCurrency(parseFloat(get(data, 'adjustment', '').toString()))}</Typography>
            },
            // {
            //     title: "Description", field: "item_errors", cellStyle: { width: 400 },
            //     render: (data: Dispute) => {
            //         // let arr = data.item_errors.replace("Issue: ", '').split('\n');
            //         // let icon = (<Box sx={{ minWidth: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", color: "rgba(229, 46, 46, 1)", background: "rgba(255, 229, 229, 1)" }} >
            //         //     <RemoveCircleOutlineIcon color="inherit" />
            //         // </Box>)
            //         return (
            //             <Box>
            //                 {/*<Typography component="p" marginBottom={1} >Missing:</Typography>*/}
            //                 <Stack direction="column" alignItems="center" gap={1} >
            //                     {/*{*/}
            //                     {/*    <LightTooltip*/}
            //                     {/*        title={*/}
            //                     {/*            <Box padding={1} >*/}
            //                     {/*                <Stack direction="row" alignItems="flex-start" gap={1} >*/}
            //                     {/*                    {icon}*/}
            //                     {/*                    <Box>*/}
            //                     {/*                        <Typography variant="subtitle2" color="rgba(229, 46, 46, 1)" paddingY={0.7} ><b>Dispute not Suggested</b></Typography>*/}
            //                     {/*                        <Stack direction="row" gap={1} alignItems="flex-start" >*/}
            //                     {/*                            <Typography variant="subtitle2" >-</Typography>*/}
            //                     {/*                            <Typography variant="subtitle2" flex={1} >{data.item_errors}</Typography>*/}
            //                     {/*                        </Stack>*/}
            //                     {/*                    </Box>*/}
            //                     {/*                </Stack>*/}
            //                     {/*            </Box>*/}
            //                     {/*        }*/}
            //                     {/*        sx={{*/}
            //                     {/*            backgroundColor: "white",*/}
            //                     {/*            [`& .${tooltipClasses.tooltip}`]: {*/}
            //                     {/*                backgroundColor: "white",*/}
            //                     {/*            }*/}
            //                     {/*        }}*/}
            //                     {/*        placement="left"*/}
            //                     {/*    >*/}
            //                     {/*        {icon}*/}
            //                     {/*    </LightTooltip>*/}
            //                     {/*}*/}
            //                     {data.item_errors.split(/\n/).filter(e => e.length).map(item =>
            //                         <TableChip key={item} label={item} />
            //                     )}
            //                 </Stack>
            //             </Box>
            //         )
            //     }
            // },
            {
              title: 'Order Date',
              field: 'order_timestamp',
              headerStyle: { minWidth: '150px' },
              render: (data) => <Typography variant="subtitle2">{moment(data.order_timestamp).format('D MMM YY')}</Typography>
            },
            {
              title: 'Time left for dispute',
              field: 'loop_raised_timestamp',
              render: (data) => {
                const remainingDays = data.num_days_to_dispute
                const loopTimeStamp = data.loop_raised_timestamp ? (
                  <Stack
                    spacing={1}
                    direction="row">
                    <img
                      src={Logo1}
                      alt="logo"
                      style={{ height: '20px', objectFit: 'contain' }}
                    />
                    <Typography
                      variant="caption"
                      color="rgba(0,0,0,0.5)"
                      component="p">
                      {moment(data.loop_raised_timestamp).format('hh:mmA, DD MMM YYYY')}
                    </Typography>
                  </Stack>
                ) : null
                if (['ACCEPTED', 'DENIED', 'IN_PROGRESS'].includes(data.external_status.toUpperCase())) {
                  const status = data.external_status
                  const color =
                    status === ExternalStatus.ACCEPTED
                      ? 'rgba(0, 149, 64, 1)'
                      : [ExternalStatus.DENIED].includes(status)
                        ? 'rgba(241, 84, 18, 1)'
                        : [ExternalStatus.IN_PROGRESS].includes(status)
                          ? 'rgba(0, 76, 229, 1)'
                          : '#144272'

                  return (
                    <Box>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}>
                        {[ExternalStatus.IN_PROGRESS, ExternalStatus.ACCEPTED].includes(status) && <StatusLogo status={status} />}
                        <Typography
                          variant="subtitle2"
                          sx={{ color, textTransform: 'capitalize' }}>
                          {data.external_status.split('_').join(' ').toLowerCase()}
                        </Typography>
                      </Stack>
                    </Box>
                  )
                } else if (
                  get(disputeData, 'self_disputable', false) &&
                  (data.dispute_status === DisputeStatus.CANCELLED_NOT_PAID || data.vb_platform.toLowerCase() === 'grubhub')
                ) {
                  return (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setDisputeConfirmData(data)
                      }}>
                      DISPUTE
                    </Button>
                  )
                } else if (get(disputeData, 'self_disputable', false) && (data.dispute_status === DisputeStatus.ELIGIBLE || !loopTimeStamp)) {
                  return (
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={
                        extraDisputeDataLoading === get(data, 'order_id', '') && (
                          <CircularProgress
                            size="24px"
                            color="inherit"
                          />
                        )
                      }
                      disabled={extraDisputeDataLoading === get(data, 'order_id', '')}
                      onClick={async () => {
                        raiseDispute(data)
                      }}>
                      DISPUTE
                    </Button>
                  )
                } else {
                  return (
                    <Box>
                      <Chip label={<span style={{ textTransform: 'capitalize' }}>{data.external_status.replaceAll('_', ' ').toLowerCase()}</span>} />
                      {loopTimeStamp ? loopTimeStamp : ''} {/* don't show if not data */}
                    </Box>
                  )
                }
              },
              // defaultSort: 'desc',
              cellStyle: {
                minWidth: 250
              }
            }
          ]}
          titleComp={
            <Box>
              <MultiSelect
                options={externalStatus.map((status) => ({ label: status.replaceAll('_', ' '), value: status }))}
                value={externalStatusSelected}
                onChange={(value) => {
                  setExternalStatusSelected(value.length === 0 ? [...externalStatus] : value)
                }}
                renderValue={(selectedValue) => (selectedValue.length === 0 ? 'Select Status' : selectedValue.join(', ').replaceAll('_', ' '))}
              />
            </Box>
          }
          emptyTemplate={<EmptyTemplate />}
          onSearchChange={setSearchTerm}
          onLastPage={() => {
            const next_offset = get(disputeData, 'next_offset', undefined)
            if (next_offset) {
              getChargebacksOrders({
                requestBody,
                limit: Math.max(100, get(disputeData, 'disputes.length', 0)),
                offset: next_offset,
                onSuccess: (data) => {
                  setDisputeData((prev) => {
                    return {
                      ...data,
                      disputes: [...get(prev, 'disputes', []), ...get(data, 'disputes', [])]
                    }
                  })
                },
                onError: (err) => {
                  handleError(err.message)
                },
                onStart: () => {
                  setTableLoading(true)
                },
                onEnd: () => {
                  setTableLoading(false)
                }
              })
            }
          }}
          onExportModifyCSV={() => DefaultService.generateCsvFromProcedureGenerateCsvPost('chargebacks/api/chargeback_orders', requestBody)}
          onExportCSV={(columns, data) => {
            downloadCSV()

            // const newColumns = columns.filter(
            //   (item) => get(item, 'hidden', false) === false
            // )
            // const csvData = data.map((rowData) => {
            //   return newColumns.map((item) => {
            //     if (
            //       item.title === 'Order Date' &&
            //       item.field === 'order_timestamp'
            //     )
            //       return moment(rowData['order_timestamp']).format('DD-MM-YY')
            //     if (
            //       item.field === 'num_days_to_dispute' &&
            //       item.title === 'Time left for dispute'
            //     ) {
            //       const remainingDays = rowData['num_days_to_dispute']
            //       const isEligible =
            //         rowData['dispute_status'].toUpperCase() === 'ELIGIBLE'
            //       let csvData
            //       if (isEligible) csvData = `${remainingDays} days`
            //       else
            //         csvData = capitalize(
            //           rowData['external_status']
            //             .replaceAll('_', ' ')
            //             .toLowerCase()
            //         )
            //       return csvData
            //     }
            //     // if (item.field === 'dispute_status' && item.title === 'Dispute Status') {
            //     //     const disputeStatus = rowData['external_status']
            //     //     const isEligible = rowData['is_eligible']
            //     //     return !isEligible ? disputeStatus : "NOT RAISED"
            //     // }
            //     if (
            //       item.field === 'order_value' ||
            //       item.field === 'error_charges'
            //     )
            //       return formatCurrency(Number(rowData[item.field]))
            //     return rowData[item.field]
            //   })
            // })

            // const fileName = 'Errors Revenue Recapture.csv'

            // trackPostHogDownload({
            //   fileName,
            //   type: 'CSV'
            // })

            // const builder = new CsvBuilder(fileName)
            //   .setColumns(newColumns.map((item) => item.title.toString()))
            //   .addRows(csvData)
            //   .exportFile()

            // return builder
          }}
          onPaginationNext={() => setPageNum((prev) => prev + 1)}
          onPaginationPrev={() => setPageNum((prev) => prev - 1)}
        />
        {/* {
                    props.recaptureData?.map((e, i) => {
                        return i < showMore ? <ErrorRevenueItem key={i} {...e} dispute={{ type: "raise" }} /> : null
                    })
                } */}
        {/* {props.recaptureData?.length > showMore && <Button variant='text' onClick={() => setShowMore(showMore + 10)}>SHOW MORE</Button>}  */}
      </Box>
    </ReasonContextProvider>
  )
}

export default ErrorsRevenueRecapture
